* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  outline: none !important;
  font-family: "Work Sans", sans-serif !important;
}

html {
  font-size: 62.5%;
  background-color: #262933;
}

html,
body,
#root {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

/*Change text in autofill textbox*/
input:-webkit-autofill {
  /*-webkit-text-fill-color: yellow !important;*/
}

/* -- Select list -- */
.MuiPaper-root .MuiMenuItem-root {
  color: #5b5b5b;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18px;
  padding: 12px 16px;
}

.MuiPaper-root .MuiMenuItem-root.Mui-selected {
  color: #5b5b5b;
  font-weight: 600;
}

.MuiList-root.MuiMenu-list {
  padding: 0;
}

/* -- Date picker -- */
/* header background */
.MuiPickersBasePicker-container .MuiPickersToolbar-toolbar {
  background: #13101b;
}

/* header texts */
.MuiPickersBasePicker-container .MuiPickersToolbar-toolbar .MuiTypography-root {
  color: #efff04;
}

/* picker view text */
.MuiPickersBasePicker-container
  .MuiPickersBasePicker-pickerView
  .MuiTypography-root {
  font-size: 12px;
}

/* selected day background */
.MuiPickersBasePicker-container
  .MuiPickersBasePicker-pickerView
  .MuiPickersDay-daySelected {
  background: #13101b;
}

/* selected day font */
.MuiPickersBasePicker-container
  .MuiPickersBasePicker-pickerView
  .MuiPickersDay-daySelected
  .MuiTypography-root {
  color: #efff04;
}
